export const DEBUG_MODE = false;
export const SUPPORTED_BARCODE_TYPE = [
  "ean13",
  "ean8",
  "upc_a",
  "upc_e",
  "upc_ean",
];
export const LOOKER_DASHBOARD_SALES =
  "my_product_profile::product_sales?MIN=&Store+ID=&Store=&Region+Name=&Channel=&theme=MorrisonsApp";
export const LOOKER_DASHBOARD_ADVANCE_PRODUCT_SEARCH =
  "my_product_profile::product_search?theme=MorrisonsApp&Category=&Class=&Subclass=&Brand=&Item%20Name=&Item%20Description=&MIN=&Store=&Primary%20Supplier=";
export const LOOKER_DASHBOARD_PRODUCT_SEARCH =
  "15?Category=&Class=&Subclass=&Brand=&Item+Name=&Item+Barcode=";
export const LOOKER_DASHBOARD_PRODUCT_DETAILS =
  "my_product_profile::product_details?theme=MorrisonsApp&MIN=&Store=";
export const LOOKER_USER_DOMAIN = "morrisonsplc.co.uk";
export const APPLICATION_GOOGLE_ANDROID_CLIENT_ID =
  "456242796688-ftos6hbmp50g80b9tjk5en0jmpjs4qlu.apps.googleusercontent.com";

export const LOOKER_APP_URL = "https://morrisonstest.cloud.looker.com";
export const CLOUD_FUNCTION_GATEWAY_URL =
  "https://mpp-service-gateway-54fcilog.nw.gateway.dev";
export const APPLICATION_GOOGLE_CLIENT_ID =
  "401455965760-eqesj462a31edi8ta5cnaq41gnn1rl6l.apps.googleusercontent.com";

const domain = window.location.hostname;

export const firebaseConfig = {
  apiKey: "AIzaSyDAWQhB2dK-EwSTr3CHh-_OMLLdx8wwl4A",
  authDomain: domain ? domain : "comm-mpp-sit.firebaseapp.com",
  projectId: "comm-mpp-sit",
  storageBucket: "comm-mpp-sit.appspot.com",
  messagingSenderId: "401455965760",
  appId: "1:401455965760:web:ce145f274627bd79a061a5",
};
